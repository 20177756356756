// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "J_gy";
export var eMarketplaceDevBusinessSection = "J_gs";
export var eMarketplaceDevCardBrdrIconSection = "J_gq";
export var eMarketplaceDevCaseSection = "J_gz";
export var eMarketplaceDevClientQuotesSection = "J_gC";
export var eMarketplaceDevExpertiseSection = "J_gw";
export var eMarketplaceDevFeaturesSection = "J_gr";
export var eMarketplaceDevIndustriesSection = "J_gt";
export var eMarketplaceDevOurAchievementsSection = "J_gB";
export var eMarketplaceDevProcessSection = "J_gv";
export var eMarketplaceDevServicesIconSection = "J_gp";
export var eMarketplaceDevTechStackSection = "J_gx";